import { IconProps } from '@/static/types';

function Quotes({ className }: IconProps) {
  return (
    <svg
      className={className}
      width='17'
      height='11'
      viewBox='0 0 17 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.667 0C11.0896 -5.63293e-07 9.00029 2.08934 9.00029 4.66666L9.00029 7.16667L13.6669 7.16667L13.667 0Z'
        fill='currentColor'
      />
      <path
        d='M9.00029 7.16667C9.00029 5.04957 10.7909 3.33333 13 3.33333C15.2091 3.33333 17 5.04957 17 7.16667C17 9.28376 15.2091 11 13 11C10.7909 11 9.00028 9.28376 9.00029 7.16667Z'
        fill='currentColor'
      />
      <path
        d='M4.66667 9.53641e-07C2.08934 3.90348e-07 1.0942e-06 2.08934 5.38769e-07 4.66666L0 7.16667L4.66666 7.16667L4.66667 9.53641e-07Z'
        fill='currentColor'
      />
      <path
        d='M0 7.16667C5.55246e-07 5.04957 1.79095 3.33333 4.00009 3.33333C6.20923 3.33333 8.00009 5.04957 8.00009 7.16667C8.00009 9.28376 6.20923 11 4.00009 11C1.79095 11 -5.55246e-07 9.28376 0 7.16667Z'
        fill='currentColor'
      />
    </svg>
  );
}

export default Quotes;
